import './styles/main.scss';

import {
  AuthPage,
  CreditProductPage,
  MerchantPage,
  PartnersPage,
  RequestsPage,
  SettingsPage,
} from 'pages';
import { Layout } from 'widgets';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { OrdersPage } from 'pages/orders-page';

import { AuthContext } from 'shared/hooks';
import { getAuthoritiesFromToken } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';

export type Authorities = {
  isAdmin: boolean;
  isCallCenter: boolean;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [isAuth, setIsAuth] = useState(!!sessionStorage.getItem('accessToken'));

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            isAuth,
            setIsAuth,
          }}
        >
          <Pages />
        </AuthContext.Provider>
      </BrowserRouter>
      <ToastContainer closeOnClick theme="colored" />
    </QueryClientProvider>
  );
};

const Pages = () => {
  const token = sessionStorage.getItem('accessToken');

  const { data: authorities } = useQuery({
    queryKey: ['authorities', token],
    queryFn: () => getAuthoritiesFromToken(token as string),
    enabled: !!token,
  });

  const isAdmin = authorities?.includes('ADMIN') ?? false;
  const isCallCenter = authorities?.includes('CALL_CENTER') ?? false;

  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      <Route element={<Layout authorities={{ isAdmin, isCallCenter }} />}>
        <Route path={RoutesPath.ORDERS} element={<OrdersPage />} />
        <Route path={RoutesPath.REQUESTS} element={<RequestsPage />} />
        {isAdmin && (
          <>
            <Route path={RoutesPath.SETTINGS} element={<SettingsPage />} />
            <Route path={RoutesPath.MERCHANTS} element={<MerchantPage />} />
            <Route path={RoutesPath.PARTNERS} element={<PartnersPage />} />
            <Route path={RoutesPath.PRODUCTS} element={<CreditProductPage />} />
          </>
        )}
      </Route>
      <Route path="*" element={<Navigate replace to={RoutesPath.ORDERS} />} />
    </Routes>
  );
};
