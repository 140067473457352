import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSortStore } from '../model';

const SortButtons = () => {
  const { direction, toggleSort } = useSortStore();

  const isUpActive = direction === 'asc';
  const isDownActive = direction === 'desc';

  return (
    <div className="flex items-center space-x-2">
      <FontAwesomeIcon
        icon={faArrowUp}
        className={`size-4 cursor-pointer   ${
          isUpActive
            ? 'text-orange-500 hover:text-orange-500'
            : 'text-white hover:text-gray-300'
        }`}
        onClick={() => toggleSort(isUpActive ? null : 'asc')} // при клике активируем или сбрасываем
      />
      <FontAwesomeIcon
        icon={faArrowDown}
        className={`size-4 cursor-pointer   ${
          isDownActive
            ? 'text-orange-500 hover:text-orange-500'
            : 'text-white hover:text-gray-300'
        }`}
        onClick={() => toggleSort(isDownActive ? null : 'desc')} // при клике активируем или сбрасываем
      />
    </div>
  );
};

export default SortButtons;
