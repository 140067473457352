import { Steps } from 'antd';

import { Description, DialogTitle, Field, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { OrderApi } from 'entities/order';
import { StatusTag } from 'entities/status';

import { Button, DialogSimple, Spinner } from 'shared/ui';

type ViewOrderDialogProps = {
  id: string | false;
  onClose: () => void;
};

export const ViewOrderDialog = ({ id, onClose }: ViewOrderDialogProps) => {
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [isOpenPaymentHistory, setIsOpenPaymentHistory] = useState(false);
  const [isOpenGoods, setIsOpenGoods] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { data } = useQuery({
    queryFn: () => OrderApi.getOrder(id as string),
    queryKey: [OrderApi.KEY, 'getOrders', id],
  });

  // const { data: product } = useQuery({
  //   queryFn: () => ProductApi.getProductsById(data.credit_product_id),
  //   queryKey: [ProductApi.KEY, 'getProductsById', data!.credit_product_id],
  //   enabled: !!isOpenPayment,
  // });

  if (!data) {
    return <Spinner />;
  }

  const renderData = [
    { title: 'Внутренний ID', value: data.internal_order_id },
    { title: 'Внешний ID', value: data.external_order_id },
    { title: 'ФИО', value: data.client_fio },
    { title: 'Общая сумма', value: data.sum },
    { title: 'Адрес доставки', value: data.delivery_address },
    { title: 'Название продавца', value: data.merchant_name },
    { title: 'Название кредитного продукта', value: data.credit_product_name },
    { title: 'Телефон', value: data.phone_number },
  ];

  return (
    <DialogSimple isOpen={!!id} onClose={onClose} className="w-full">
      <DialogTitle
        as="h3"
        className="flex items-center gap-3 text-xl font-medium text-white"
      >
        <span> Заказ № {data.internal_order_id} </span>
        <span>{data.date}</span>
        {!!data.order_status_history.length ? (
          <span>{data.order_status_history[0].status}</span>
        ) : (
          <StatusTag status={data.status} />
        )}
      </DialogTitle>
      <div className="mt-3 flex flex-col gap-4">
        <div className="grid grid-cols-[66.6666666666666%_1fr] gap-2">
          <div className="mt-3 grid h-fit grid-cols-2 gap-3">
            {renderData.map((item) => (
              <Field key={item.title}>
                <Label className="text-sm font-medium text-white">
                  {item.title}
                </Label>
                <Description className="text-sm/6 text-white/50">
                  {item.value}
                </Description>
              </Field>
            ))}
          </div>
          <Steps
            progressDot
            current={3}
            direction="vertical"
            items={data.order_status_history.map((item) => ({
              title: item.status,
              description: item.date,
            }))}
          />
        </div>
        <div className="mx-auto flex items-center gap-2">
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => setIsOpenGoods(true)}
          >
            Товары по заказу
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => setIsOpenPayment(true)}
          >
            График платежей
          </Button>
          <Button
            className="w-48 rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => setIsOpenPaymentHistory(true)}
          >
            История платежей
          </Button>
        </div>
      </div>
      <DialogSimple
        isOpen={isOpenGoods}
        onClose={() => setIsOpenGoods(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          Товары по заказу
        </DialogTitle>
        <div className="flex flex-col gap-4">
          {[data.cart.cart_items, data.items_returned].map(
            (item, index) =>
              !!item && (
                <div key={index} className="overflow-x-auto">
                  <p className="py-3 text-sm font-medium text-white">
                    {!index ? 'Оформленные' : 'Возврат'}
                  </p>
                  <table className="w-full text-left text-sm text-brand-1">
                    <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
                      <tr>
                        {[
                          'Категория',
                          'Наименование',
                          'Артикль',
                          'Цена',
                          'Количество',
                          'Сумма',
                          'Cтатус',
                        ].map((item) => (
                          <th key={item} scope="col" className="px-6 py-3">
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.cart.cart_items.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                          >
                            {Object.values(item.item).map((item) => (
                              <td className="px-6 py-4 text-center">{item}</td>
                            ))}
                            <td className="px-6 py-4 text-center">
                              {item.quantity}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {item.sum}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {item.status}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ),
          )}
        </div>
      </DialogSimple>
      <DialogSimple
        isOpen={isOpenPayment}
        onClose={() => setIsOpenPayment(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          График платежей
        </DialogTitle>
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {[
                'Номер платежа',
                'Дата платежа',
                'Сумма платежа',
                'Сумма к оплате',
              ].map((item) => (
                <th scope="col" className="px-6 py-3">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.payment_plan
              //@ts-ignore
              .sort((a, b) => +a.stage > +b.stage)
              .map((item) => {
                return (
                  <tr
                    key={item.stage}
                    className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    {Object.values(item).map((item) => (
                      <td className="px-6 py-4 text-center">{item}</td>
                    ))}
                    <th scope="col" className="px-6 py-3">
                      <Button
                        className="rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                        onClick={() => alert('в разработке')}
                      >
                        оплатить
                      </Button>
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </DialogSimple>
      <DialogSimple
        isOpen={isOpenPaymentHistory}
        onClose={() => setIsOpenPaymentHistory(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          История платежей
        </DialogTitle>
        {data.payments ? (
          <table className="mt-3 w-full text-left text-sm text-brand-1">
            <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
              <tr>
                {['Статус', 'Дата платежа', 'Сумма платежа', 'Действия'].map(
                  (item) => (
                    <th scope="col" className="px-6 py-3 text-center">
                      {item}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {data.payments
                //@ts-ignore
                .sort((a, b) => +a.stage > +b.stage)
                .map((item) => {
                  return (
                    <tr
                      key={item.id}
                      className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 text-center">{item.status}</td>
                      <td className="px-6 py-4 text-center">{item.date}</td>
                      <td className="px-6 py-4 text-center">{item.paidSum}</td>
                      <th scope="col" className="px-6 py-3">
                        <Button
                          className="rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                          onClick={() => setIsDetailsOpen(true)}
                        >
                          Детализация
                        </Button>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <p>История пуста</p>
        )}
      </DialogSimple>
      <DialogSimple
        isOpen={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          Детализация
        </DialogTitle>
        {data.payments.length ? (
          <table className="mt-3 w-full text-left text-sm text-brand-1">
            <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
              <tr>
                {[
                  'Статус',
                  'Сумма платежа',
                  'Номер карты',
                  'Код ошибки',
                  'Сообщение ошибки',
                  'Детали ошибки',
                ].map((item) => (
                  <th scope="col" className="px-6 py-3 text-center">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.payments.map((item) =>
                item.details.map((detail) => {
                  return (
                    <tr
                      key={item.id}
                      className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 text-center">{detail.status}</td>
                      <td className="px-6 py-4 text-center">{detail.amount}</td>
                      <td className="px-6 py-4 text-center">{detail.pan}</td>
                      <td className="px-6 py-4 text-center">
                        {detail.errorCode}
                      </td>
                      <td className="px-6 py-4 text-center">
                        {detail.errorMessage}
                      </td>
                      <td
                        className="px-6 py-4 text-center"
                        title={detail.errorDetails || ''}
                      >
                        Детали
                      </td>
                    </tr>
                  );
                }),
              )}
            </tbody>
          </table>
        ) : (
          <p>История пуста</p>
        )}
      </DialogSimple>
    </DialogSimple>
  );
};
