import { Fragment, useState } from 'react';

import SortButtons from 'features/sort-buttons/ui/sort-buttons';
import { ViewOrderDialog } from 'features/view-order/ui';

import { OrderApi } from 'entities/order/Order.api';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

type OrderBase = {
  date: string;
  id: string;
  number: string;
  clientFio: string;
  status: string;
  flow: string;
  sum: string;
  internal_order_number: string;
};

export const OrdersTable = () => {
  const [isOpenId, setIsOpenId] = useState<string | false>(false);

  const columns: AccessorKey<OrderBase>[] = [
    { accessorKey: 'id' },
    { accessorKey: 'date' },
    { accessorKey: 'number' },
    { accessorKey: 'clientFio' },
    { accessorKey: 'status' },
    { accessorKey: 'flow' },
    { accessorKey: 'sum' },
    { accessorKey: 'internal_order_number' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="flex w-[50px] min-w-fit whitespace-nowrap px-6 py-3 text-center"
      >
        <span className="pr-2">дата</span>
        <SortButtons />
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        номер
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        внутренний номер
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        ФИО клиента
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        статус
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        тип магазина
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        сумма (валюта)
      </th>
    </tr>
  );

  const renderRow = (order: OrderBase) => {
    return (
      <tr
        key={order.id}
        className="h-12 cursor-pointer border-gray-700 bg-white hover:bg-brand-1/20 [&:not(:last-child)]:border-b"
        onClick={() => setIsOpenId(order.id)}
        title="Просмотр"
      >
        <td className="px-6 py-4">{order.date}</td>
        <td className="px-6 py-4">{order.id}</td>
        <td className="px-6 py-4">{order.internal_order_number}</td>
        <td className="px-6 py-4">{order.clientFio}</td>
        <td className="px-6 py-4">{order.status}</td>
        <td className="px-6 py-4">{order.flow}</td>
        <td className="px-6 py-4">{order.sum}</td>
      </tr>
    );
  };

  return (
    <Fragment>
      <Table
        queryKeys={[OrderApi.KEY, 'getOrders']}
        queryFn={OrderApi.getOrders}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {isOpenId && (
        <ViewOrderDialog id={isOpenId} onClose={() => setIsOpenId(false)} />
      )}
    </Fragment>
  );
};
