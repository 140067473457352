import { Fragment, useState } from 'react';

import SortButtons from 'features/sort-buttons/ui/sort-buttons';
import { ViewRequestDialog } from 'features/view-request/ui';

import { RequestApi } from 'entities/request/Request.api';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

type OrderBase = {
  date: string;
  id: string;
  number: string;
  clientFio: string;
  status: string;
  request_flow: string;
  sum: string;
};

export const RequestsTable = () => {
  const [isOpenId, setIsOpenId] = useState<string | false>(false);

  const columns: AccessorKey<OrderBase>[] = [
    { accessorKey: 'id' },
    { accessorKey: 'date' },
    { accessorKey: 'number' },
    { accessorKey: 'clientFio' },
    { accessorKey: 'status' },
    { accessorKey: 'request_flow' },
    { accessorKey: 'sum' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="flex w-[50px] min-w-fit whitespace-nowrap px-6 py-3 text-center"
      >
        <span className="pr-2">дата</span>
        <SortButtons />
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        номер
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        ФИО клиента
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        статус
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        тип магазина
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        сумма (валюта)
      </th>
    </tr>
  );

  const renderRow = (request: OrderBase) => {
    return (
      <tr
        key={request.id}
        className="brequest-gray-700 [&:not(:last-child)]:brequest-b h-12 cursor-pointer bg-white hover:bg-brand-1/20"
        onClick={() => setIsOpenId(request.id)}
        title="Просмотр"
      >
        <td className="px-6 py-4">{request.date}</td>
        <td className="px-6 py-4">{request.id}</td>
        <td className="px-6 py-4">{request.clientFio}</td>
        <td className="px-6 py-4">{request.status}</td>
        <td className="px-6 py-4">{request.request_flow}</td>
        <td className="px-6 py-4">{request.sum}</td>
      </tr>
    );
  };

  return (
    <Fragment>
      <Table
        queryKeys={[RequestApi.KEY, 'getRequests']}
        queryFn={RequestApi.getRequests}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {isOpenId && (
        <ViewRequestDialog id={isOpenId} onClose={() => setIsOpenId(false)} />
      )}
    </Fragment>
  );
};
