import { cx } from '@emotion/css';

import type { Status } from '../model';

export const StatusTag = ({ status = 'NEW' }: { status: Status }) => {
  let hue = 0,
    text = '';

  switch (status) {
    case 'NEW':
      hue = 220;
      text = 'Новый';
      break;
    case 'EXPIRED':
      hue = 50;
      text = 'Просрочен';
      break;
    case 'PARTIAL_REFUND':
      hue = 50;
      text = 'Частичный возврат';
      break;
    case 'RETURN':
      hue = 0;
      text = 'Возврат';
      break;
    case 'PAYED':
    case 'AUTHORIZED':
      hue = 120;
      text = 'Оплачен';
      break;
    case 'ISSUED':
      hue = 280;
      text = 'Выдан';
      break;
  }

  return (
    <span
      className={cx(`text-xs font-semibold rounded-md border px-2 py-1`)}
      style={{
        borderColor: `hsl(${hue}, 50%, 40%)`,
        color: `hsl(${hue}, 50%, 20%)`,
        backgroundColor: `hsl(${hue}, 50%, 90%)`,
      }}
    >
      {text}
    </span>
  );
};
