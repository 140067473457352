import { faUsers } from '@fortawesome/free-solid-svg-icons';

import { useState } from 'react';

import { AddUserDialog } from 'features/add-user/ui';

import { UserApi } from 'entities/user';

import { ButtonPlusIcon, PageHeading, PageWrapper } from 'shared/ui';

import { UsersTable } from './ui';

export const SettingsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PageWrapper className="relative">
      <div className="flex justify-between">
        <PageHeading
          icon={faUsers}
          title="Пользователи"
          refetchQueryFilters={[UserApi.KEY, 'getUsers']}
        />
        <div className="flex justify-end gap-2 py-2">
          <ButtonPlusIcon
            text="Добавить пользователя"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
      <UsersTable />
      <AddUserDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </PageWrapper>
  );
};
