import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { AddMerchantDialogConfirm } from 'features/add-merchant/ui/add-merchant-dialog-confirm';
import { SelectPartner } from 'features/add-partner/ui';

import { MerchantApi, SelectOrderFlow } from 'entities/merchant';

import { onAxiosError } from 'shared/lib';
import {
  DialogSimple,
  DialogSimpleProps,
  Button as SharedButton,
} from 'shared/ui';

type AddMerchantDialogProps = {
  onClick?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  name: yup.string().trim().min(4, 'Минимум 4 символа').required(),
  description: yup.string().trim().min(4, 'Минимум 4 символа').required(),
  order_flow: yup.string().required(),
  partner_id: yup.number().required(),
  active: yup.boolean().default(true),
});

export const AddMerchantDialog = ({
  isOpen,
  onClose,
}: AddMerchantDialogProps) => {
  const client = useQueryClient();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [keysData, setKeysData] = useState({ publicKey: '', privateKey: '' });

  const onSubmit = async (data: InferType<typeof schema>) => {
    addMerchantMutation.mutate(data);
  };

  const addMerchantMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => MerchantApi.create(data),
    onSuccess: (data) => {
      if (data.publicKey) {
        setIsOpenConfirm(true);
        setKeysData(data);
      }
      client.refetchQueries({ queryKey: [MerchantApi.KEY, 'getMerchants'] });
      toast.success('Успешно');
    },
    onError: onAxiosError,
  });

  return (
    <Fragment>
      <DialogSimple isOpen={isOpen} onClose={console.log}>
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          Добавление магазина
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field className="mt-3">
            <Label className="text-sm/6 font-medium text-white">Имя</Label>
            <Input
              {...register('name')}
              className={cx(
                'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                'focus:outline-none',
              )}
            />
          </Field>
          <Field className="mt-3">
            <Label className="text-sm/6 font-medium text-white">Описание</Label>
            <Input
              {...register('description')}
              className={cx(
                'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                'focus:outline-none',
              )}
            />
          </Field>
          <Field className="mt-3">
            <Label className="text-sm/6 font-medium text-white">Тип</Label>
            <SelectOrderFlow setValue={setValue} />
          </Field>
          <Field className="mt-3">
            <Label className="text-sm/6 font-medium text-white">Партнер</Label>
            <SelectPartner setValue={setValue} valueKey="partner_id" />
          </Field>
          <div className="mt-4 flex gap-2">
            <Button
              className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
              onClick={onClose}
            >
              Отмена
            </Button>
            <SharedButton
              className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
              disabled={addMerchantMutation.isPending}
            >
              Добавить
            </SharedButton>
          </div>
        </form>
        <div className="mt-3 flex flex-col gap-1">
          {errors &&
            Object.values(errors).map(({ message }) => (
              <span
                key={message}
                className="text-sm/6 font-medium text-rose-400"
              >
                {message}
              </span>
            ))}
        </div>
      </DialogSimple>
      {!!Object.keys(keysData).length && (
        <AddMerchantDialogConfirm
          isOpen={isOpenConfirm}
          keysData={keysData}
          onContinue={() => {
            onClose();
            setIsOpenConfirm(false);
          }}
          onClose={() => setIsOpenConfirm(false)}
        />
      )}
    </Fragment>
  );
};
