import { create } from 'zustand/react';

type Direction = 'asc' | 'desc' | null;

interface SortState {
  direction: Direction;
  toggleSort: (direction: Direction) => void;
}

export const useSortStore = create<SortState>((set) => ({
  direction: null,
  toggleSort: (direction: Direction) => {
    set((state: SortState) => {
      if (state.direction === direction) {
        return { direction: null };
      }
      return { direction };
    });
  },
}));
